jQuery( document ).ready( function ( $ ) {
	$( 'header .hamburger' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '#nav-collapse' ).toggleClass( 'open' );
	} );

	$( 'nav>ul>li>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );
		$( 'html, body' ).animate( {
			scrollTop: $( href ).offset().top
		}, 2000 );
	} );
} );
